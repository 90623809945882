.root {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
}

.alertContainer {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    box-sizing: border-box;
    background-color: #fff !important;
    padding: 8px 20px !important;
    border-radius: 8px !important;
}

.actionItems {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.button {
    cursor: pointer;
    font-weight: bold;
    color: black;
    align-self: center;
}
