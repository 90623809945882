.container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;
}

.button {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    padding: 10px;
    border-radius: 32px;
    transition: background-color 0.3s ease;
    border: 1px solid #656b80;
}

.button:hover {
    background-color: #d8d8d8;
}

.button span {
    font-size: 16px;
    color: #333;
}

@media (min-width: 744px) {
    .container {
        gap: 24px;
    }

    .button {
        padding: 8px 16px;
        font-size: 18px;
    }
}

@media (max-width: 480px) {
    .button {
        padding: 8px 16px;
        font-size: 14px;
    }
}
